class MapSubMenuStatus {
	get() {
		if (process.browser) {
			return localStorage ? localStorage.getItem("MAP_TYPE") || MapType.apt : MapType.apt
		}
	}
	update(type: MapType) {
		if (process.browser) {
			localStorage.setItem("MAP_TYPE", type)
		}
	}
	clear() {
		if (process.browser) {
			localStorage.removeItem("MAP_TYPE")
		}
	}
	from(type: string | string[]) {
		if (typeof type === "string") {
			switch (type) {
				case "원룸":
					return MapType.oneroom
				case "아파트":
					return MapType.apt
				case "오피스텔":
					return MapType.officetel
				case "빌라":
					return MapType.villa
				case "상가":
					return MapType.store
				case "입주예정":
					return this.get() as MapType
				// no default
			}
		}
		return MapType.apt
	}
}

export enum MapType {
	apt = "apt",
	offer = "offer",
	population = "population",
	villa = "villa",
	officetel = "officetel",
	oneroom = "oneroom",
	store = "store",
}

export const mapSubMenuStatus = new MapSubMenuStatus()
