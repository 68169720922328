import React from "react"
import styled from "styled-components"

export class Footer extends React.PureComponent {
	render() {
		return (
			<FooterStyle>
				<div className="wrap-600">
					<p className="i-links">
						<a href="//company.zigbang.com" target="_blank">
							회사소개
						</a>{" "}
						|
						<a
							href="//career.zigbang.com/?utm_source=zigbang_home&utm_medium=homepage&utm_campaign=usual"
							target="_blank">
							채용정보
						</a>{" "}
						|
						<a href="//static.zigbang.com/terms/terms-of-use/latest/" target="_blank">
							이용약관
						</a>{" "}
						|
						<a href="//static.zigbang.com/terms/user-privacy/latest/" target="_blank">
							<strong>개인정보 처리방침</strong>
						</a>{" "}
						|
						<a href="//static.zigbang.com/terms/location-service/latest/" target="_blank">
							위치기반 서비스 이용약관
						</a>{" "}
						|
						<a href="//ceo.zigbang.com" target="_blank">
							중개사 사이트 바로가기
						</a>
					</p>
					<div className="i-copyright">
						상호 : (주)직방 &nbsp;|&nbsp; 대표 : 안성우 &nbsp;|&nbsp; 사업자등록번호: 120-87-61559
						<br />
						주소 : 서울특별시 강남구 영동대로 731, 지하 2층 (청담동, 신영빌딩) (우 : 06072)
						<br />
						통신판매업 신고번호 : 제2021-서울강남-06358호
						<br />
						서비스 이용문의: 1661-8734 &nbsp;|&nbsp; 이메일 :{" "}
						<a href="mailto:cs@zigbang.com">cs@zigbang.com </a> &nbsp;|&nbsp; 팩스 : 02-568-4908
						<br />
						서비스제휴문의 : <a href="mailto:partnership@zigbang.com">partnership@zigbang.com</a>{" "}
						&nbsp;|&nbsp; 분양광고 문의 : <a href="mailto:ad@zigbang.com">ad@zigbang.com</a>
						<p>
							<a href="https://www.facebook.com/zigbangpage" target="_blank">
								<img src="//s.zigbang.com/v1/web/common/ico_t2_sns1.png" alt="" />
							</a>
							<a href="https://www.youtube.com/channel/UCiUQWRA_xsGzvNVGFlBKBiQ" target="_blank">
								<img src="//s.zigbang.com/v1/web/common/ico_sns_youtube.png" alt="" />
							</a>
							<a href="https://blog.naver.com/zigbang" target="_blank">
								<img src="//s.zigbang.com/v1/web/common/ico_sns_blog.png" alt="" />
							</a>
							<a href="http://post.naver.com/zigbang" target="_blank">
								<img src="//s.zigbang.com/v1/web/common/ico_t2_sns4.png" alt="" />
							</a>
						</p>
						<div className="copyright">Copyright © ZIGBANG. All Rights Reserved.</div>
					</div>
				</div>
			</FooterStyle>
		)
	}
}

const FooterStyle = styled.div`
	flex-shrink: 1;
	min-height: 298px;
	width: 100%;
	margin: 0 auto 0;
	overflow: hidden;
	background: #ffffff;
	color: #999;
	padding: 52px 0 20px;
	position: relative;
	text-align: center;
	.wrap-600 {
		width: 600px;
		margin: 0 auto;
		position: relative;
	}
	.i-links {
		font-size: 12px;
		padding-bottom: 20px;
		a {
			color: #000;
			padding: 0 4px;
			font-size: 12px;
			line-height: 1.3;
			&.i_privacy {
				font-weight: bold;
				text-decoration: underline;
			}
			&:first-child {
				padding-left: 0;
			}
			strong {
				font-weight: bold;
			}
		}
	}
	.i-txt {
		padding: 0 0 5px 0;
		font-size: 13px;
		color: #c1a772;
		font-weight: bold;
	}
	.i-copyright {
		color: #4c4c4c;
		font-size: 12px;
		line-height: 1.6em;
		p {
			padding: 24px 0 13px;
			text-align: center;
			a {
				display: inline-block;
				margin-right: 15px;
				img {
					width: 34px;
				}
			}
		}
		.copyright {
			text-align: center;
			color: #a6a6a6;
		}
		div {
			color: #808080;
		}
	}
	.icon-footer {
		position: absolute;
		left: 90px;
		top: 0;
		width: 90px;
		height: 32px;
		background: url("//s.zigbang.com/v1/web/common/icon_footer.png") no-repeat 0 0;
	}
	.detail_footer {
		background-color: #f0f0f0;
		border: solid 1px #e1e1e1;
		font-size: 12px;
		line-height: 1.67;
		text-align: center;
		color: #757575;
		padding: 20px;
		margin-top: -27px;
		margin-bottom: 60px;
		z-index: 1;
		position: relative;
	}
`
