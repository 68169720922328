import "regenerator-runtime"
import App, { AppContext } from "next/app"
import { WithRouterProps } from "next/dist/client/with-router"
import NextHead from "next/head"
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { Rnd } from "react-rnd"
import { ribbonCorner } from "ribbon-corner"
import styled from "styled-components"
import type { StackNavigationOptions } from "@react-navigation/stack"
import { zidHandler, unSupportedUserAgent } from "@zb/common"
import { ZigbangAPI } from "@zigbang/api"
import { ZustandProvider } from "@zigbang/screens/components/zustand/Provider"
import { withCommonConfig } from "@zigbang/screens/hoc/withCommonConfig"
import { DeviceInfos, initializePinpoint, sendEvent, ZB_EVENT_TYPE } from "@zigbang/screens/lib"
import { LogEventType, loginStore } from "@zigbang/screens/lib/Account"
import { CacheProvider } from "@zigbang/screens/lib/CacheProvider"
import { Constants } from "@zigbang/screens/lib/Constants"
import { NavigationProvider } from "@zigbang/screens/lib/navigation/NavigationContext"
import { LayoutOptions, LayoutType, ReactNavigationMapper } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { overrideNavigationV4 } from "@zigbang/screens/lib/navigation/ReactNavigationMapper/navigationV4"
import { sentry } from "@zigbang/screens/lib/sentry"
import { PerformanceScreen } from "@zigbang/screens/performance/PerformanceScreen"
import * as Zuix from "@zigbang/zuix"
import * as Zuix2 from "@zigbang/zuix2"
import routes, { getPathForSSR, Router } from "../routes"
import { mapSubMenuStatus, MapType } from "../src/components/config"
import { Footer, Header } from "../src/components/layout"
import {
	AptMapLayout,
	OfficetelLayout,
	OneroomLayout,
	StoreLayout,
	VillaLayout,
	AptMapPopulationLayout,
} from "../src/components/mapLayout"
import { MapLayout, MapLayoutFunc, SubRouter } from "../src/router/subRouter"
import "@zigbang/chart/dist/chart.min.css"
import "../style/global.css"
import "../style/zuixGlobalStyle"
import { ssrMiddleware } from "../src/ssrMiddleware"
import { PerPageLayout } from "../@types/layout"

// import { Logger } from "@zigbang/bridge/pinpoint"
// if (__DEV__) Logger.LOG_LEVEL = "DEBUG"

const initPinPointWithEndPointId = async () => {
	const { value: adid, uuid } = await DeviceInfos.getAdIdObject()
	initializePinpoint({ endpointId: adid ?? uuid ?? "" })
}
initPinPointWithEndPointId()

loginStore.initStore({
	accountUrl: process.env.ACCOUNT_HOST ?? "https://account.zigbang.com",
	userInfoSaltKey: process.env.USER_INFO_SALT_KEY ?? "KSLucUrYuiKySzeEEzOIiBrSxVnn3zt4",
	domain: "zigbang_app",
	clientId: "zigbang",
	cookieDomain: process.env.ZAUTH_COOKIE_DOMAIN,
	logger: (type, message) => {
		switch (type) {
			case LogEventType.ERROR:
				sendEvent(ZB_EVENT_TYPE.THROW_ERROR, message)
				break
			case LogEventType.LOGOUT_INVALID_USER:
				sendEvent(ZB_EVENT_TYPE.ZB_CONTENT_IMPRESSION, {
					screen_name: "중개사메인",
					dialog_name: 403,
					status_value: "CEO에러팝업",
					status: true,
				})
				break
			case LogEventType.LOGOUT_WITHDRAWAL:
				sendEvent(ZB_EVENT_TYPE.ZB_CONTENT_IMPRESSION, {
					dialog_name: 403,
					status_value: "유저에러팝업",
					status: false,
				})
				break
			default:
				break
		}
	},
})

ZigbangAPI.PLATFORM = DeviceInfos.getSystemName()

sentry.init()
try {
	const user = { id: DeviceInfos.getZId() ?? "", detailInfo: "" }
	sentry.setUser(user)
} catch (error) {
	console.warn(error)
}

export interface ZbUser {
	email: string
	user_type: string
	room_count: string
	user_name: string
	phone: string
	password: string
	secure_token: string
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})

export default class MyApp extends App<
	{
		Component: {
			navigationOptions: StackNavigationOptions
			layoutOptions: LayoutOptions
		} & PerPageLayout
		routeName: string
	},
	{ [key: string]: any }
> {
	static getDerivedStateFromProps(
		nextProps: WithRouterProps,
		state: { query: { [key: string]: string }; _asPath: any }
	) {
		const { query, asPath } = nextProps.router
		if (state._asPath !== asPath) {
			return { ...state, query, _asPath: asPath }
		} // 라우팅이 바뀌었을때는 경로를 기존 state 날림
		return { ...state, query: { ...state.query, ...query } }
	}

	static async getInitialProps(appContext: AppContext) {
		const { router, ctx, Component } = appContext
		const { res, req } = ctx
		if (res && req) {
			ssrMiddleware({ res, req })
			unSupportedUserAgent({ res, req })
			zidHandler({ res, req })
		}
		return { query: router.query, pageProps: (await Component.getInitialProps?.(ctx)) ?? {} }
	}

	state = {
		query: this.props.router.query,
	}

	layoutOptions: LayoutOptions = {
		layoutType: LayoutType.Full,
		noFooter: false,
		twoDepthHeader: false,
	}

	navigation?: ReactNavigationMapper

	updateNavigationState = (query: any) => {
		this.setState({ query })
		return true
	}

	async componentDidMount() {
		await withCommonConfig.initialize()

		document.addEventListener("contextmenu", (event) => {
			event.preventDefault()
			return false
		})

		if (this.props.router.query.initData === "true") {
			window?.localStorage.removeItem(Constants.Keys.KEY_CONTACT_MESSAGE_PHONE_NUMBER)
			history.replaceState?.({}, "", location.pathname)
		}

		if (process.env.STAGE === "dev" || process.env.STAGE === "preview") {
			ribbonCorner({
				backgroundColor: process.env.STAGE === "dev" ? "#67c23a" : "#e6a23c",
				toCorner: 30,
				height: 20,
				text: process.env.STAGE,
			})
		}
	}

	render() {
		const {
			Component: Page,
			pageProps,
			router: { pathname, query: routeQuery, route, asPath: _asPath },
		} = this.props
		const asPath = typeof window === "undefined" ? getPathForSSR(pathname, routeQuery) ?? _asPath : _asPath

		const customRoute = (routes as any).match(asPath)
		const routeName = customRoute.route && customRoute.route.name ? customRoute.route.name : ""
		const { query = {} } = this.state

		const getLayout = Page.getLayout ?? ((page) => page)
		let TwiceScreenLayout = Page

		const {
			meta: {
				title = "",
				description = "",
				keywords = "",
				ogTitle = "",
				ogDescription = "",
				ogImage = null,
			} = {},
		} = pageProps || {}
		const path = asPath || ""
		const ogUrl = process.env.CURRENT_DOMAIN + path
		const 선호URL = process.env.CURRENT_DOMAIN + path

		this.웹과_스크린_인증정보_연결하기()

		if (aptMapRouter.has(pathname)) {
			const { layout, mapType } = aptMapRouter.get(pathname)!

			if (mapType) {
				mapSubMenuStatus.update(mapType)
			} else if (!this.navigation && this.state.query.service_type) {
				mapSubMenuStatus.update(mapSubMenuStatus.from(this.state.query.service_type))
			}

			if (layout.prototype && !!layout.prototype.isReactComponent) {
				// @ts-ignore
				TwiceScreenLayout = layout
			} else {
				// @ts-ignore
				TwiceScreenLayout = (layout as MapLayoutFunc)(mapSubMenuStatus.get() as MapType)
			}
		}
		const { layoutOptions } = TwiceScreenLayout as MapLayout<unknown>
		this.navigation = new ReactNavigationMapper(routeName, this.state.query, Router, this.updateNavigationState)
		const navigationRoute = { key: "", name: this.props.routeName, params: this.navigation.params, path: asPath }
		overrideNavigationV4(this.navigation, navigationRoute)
		const { noFooter, fixedHeader, twoDepthHeader } = { ...this.layoutOptions, ...layoutOptions }
		const props = {
			...query,
			...pageProps,
			query,
			navigation: this.navigation,
			routeName,
			navigationRoute,
		}
		if (aptMapRouter.has(pathname)) {
			Object.assign(props, { rightScreen: Page, router: this.props.router })
		}

		return (
			<>
				<NextHead>
					<title>{title || "Beyond Home, Zigbang"}</title>
					<meta
						name="description"
						content={
							description ||
							"원룸, 오피스텔, 빌라부터 아파트와 분양까지 모든 매물의 정보를 직방에서 확인해보세요."
						}
					/>
					<meta
						name="keywords"
						content={
							keywords ||
							"원룸, 투룸, 쓰리룸, 빌라, 오피스텔, 아파트 실거래가, 시세, 매매, 전세, 월세, 매물, 거주민 리뷰, 분양정보, 신축분양, 분양"
						}
					/>
					<meta property="og:site_name" content="직방" />
					<meta property="og:local" content="ko_KR" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={ogUrl} />
					<meta property="og:title" content={ogTitle || "No.1 부동산 앱, 직방"} />
					<meta property="og:description" content={ogDescription || "여기를 눌러 링크를 확인하세요."} />
					<meta
						property="og:image"
						content={ogImage || `${process.env.S_HOST}/v2/web/og/zigbang_default_new.png?v=20200709`}
					/>
					<link rel="canonical" href={선호URL} />
					<style>{`body { display: block !important }`}</style>
				</NextHead>
				<Header mode={fixedHeader ? "fixed" : "relative"} asPath={asPath || ""} route={route} />
				<Body mode={fixedHeader ? "fixed" : "relative"} twoDepth={twoDepthHeader}>
					<CacheProvider>
						<Zuix.ProviderToast options={{ position: "bottom", duration: 1000 }}>
							<Zuix2.Provider>
								<QueryClientProvider client={queryClient}>
									<NavigationProvider navigation={this.navigation} route={navigationRoute}>
										<Zuix.AppThemeProvider appTheme="zigbang">
											<ZustandProvider initialState={pageProps.initialZustandState}>
												{getLayout(<TwiceScreenLayout {...props} />)}
												<PerformanceScreen panelComponent={Rnd} panelProps={{}} />
											</ZustandProvider>
										</Zuix.AppThemeProvider>
									</NavigationProvider>
								</QueryClientProvider>
							</Zuix2.Provider>
						</Zuix.ProviderToast>
					</CacheProvider>
				</Body>
				{!noFooter && <Footer />}
			</>
		)
	}

	async 웹과_스크린_인증정보_연결하기() {
		if (!process.browser) {
			return
		}

		const 로그인했나요 = await loginStore.isLoggedIn()
		if (!로그인했나요) {
			loginStore.인증정보_삭제하기()
		}
	}
}

const Body = styled.div`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	${(props: { mode: "fixed" | "relative"; twoDepth: boolean }) =>
		props.mode === "fixed"
			? `
		margin-top: ${props.twoDepth ? 120 : 79}px;
		@media screen and (max-width: 1199px) {
			margin-top: 0;
		}
	`
			: "0px;"}
`

const aptMapRouter = new SubRouter()
aptMapRouter.set("/home/apt_map", { layout: AptMapLayout, mapType: MapType.apt })
aptMapRouter.set("/home/apt_danjis_detail", { layout: AptMapLayout }) // 아파트매물 | 신축분양
aptMapRouter.set("/home/apt_map_offer", { layout: AptMapLayout, mapType: MapType.offer })
aptMapRouter.set("/home/apt_danjis_offer", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_locals_detail", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_locals_danjis", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_rank", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_trade", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_monthly_report", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_updown_trend", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_highest_lowest", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_local_rank", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_locals_danjis_tab", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_schools_detail", { layout: AptMapLayout }) // 아파트매물 | 신축분양 | 빌라
aptMapRouter.set("/home/apt_danjis_info", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_danjis_vr", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_danjis_items", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_add_intro", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_add_search", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_add_search_modal", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_add_complete", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_add_agent_office_list", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_list", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_favorite_item_list", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_mediation_list", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_web_home_tour", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_schools_danjis", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_danjis_contents", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_danjis_reviews_add", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_danjis_reviews", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_subways_detail", { layout: AptMapLayout, mapType: MapType.apt })
aptMapRouter.set("/home/apt_subways_danjis", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_vr_detail", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_map_population", { layout: AptMapPopulationLayout, mapType: MapType.population })
aptMapRouter.set("/home/apt_agent_profile", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_agent_profile_list", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_danjis_related", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_item_detail", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_danji_item_list", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_agent_review_list", { layout: AptMapLayout })
aptMapRouter.set("/home/apt_items_list", { layout: AptMapLayout }) // 아파트 지역 매물 리스트

aptMapRouter.set(["/profiles/detail", "/home/message", "/contact", "/settings/cs", "/home/safe_agent_list"], {
	layout: (mapType: MapType) => {
		switch (mapType) {
			case MapType.apt:
				return AptMapLayout
			case MapType.offer:
				return AptMapLayout
			case MapType.population:
				return AptMapPopulationLayout
			case MapType.villa:
				return VillaLayout
			case MapType.oneroom:
				return OneroomLayout
			case MapType.officetel:
				return OfficetelLayout
			case MapType.store:
				return StoreLayout
			default:
				return AptMapLayout
		}
	},
}) // 원룸 | 오피스텔 | 빌라 | 아파트매물 | 신축분양

aptMapRouter.set("/home/oneroom_map", { layout: OneroomLayout, mapType: MapType.oneroom })
aptMapRouter.set("/home/oneroom_items", { layout: OneroomLayout, mapType: MapType.oneroom })
aptMapRouter.set("/home/oneroom_items_detail", { layout: OneroomLayout, mapType: MapType.oneroom })
aptMapRouter.set("/home/oneroom_subways_items", { layout: OneroomLayout, mapType: MapType.oneroom })
aptMapRouter.set("/home/oneroom_schools_detail", { layout: OneroomLayout, mapType: MapType.oneroom }) // 아파트매물 | 신축분양 | 빌라

aptMapRouter.set("/home/officetel_map", { layout: OfficetelLayout, mapType: MapType.officetel })
aptMapRouter.set("/home/officetel_items", { layout: OfficetelLayout, mapType: MapType.officetel })
aptMapRouter.set("/home/officetel_danjis_items", { layout: OfficetelLayout, mapType: MapType.officetel })
aptMapRouter.set("/home/officetel_subways_items", { layout: OfficetelLayout, mapType: MapType.officetel })
aptMapRouter.set("/home/officetel_items_detail", { layout: OfficetelLayout, mapType: MapType.officetel })
aptMapRouter.set("/home/officetel_schools_detail", { layout: OfficetelLayout, mapType: MapType.officetel })

aptMapRouter.set("/home/villa_map", { layout: VillaLayout, mapType: MapType.villa })
aptMapRouter.set("/home/villa_items", { layout: VillaLayout, mapType: MapType.villa })
aptMapRouter.set("/home/villa_inquiry_tour", { layout: VillaLayout, mapType: MapType.villa })
aptMapRouter.set("/home/villa_danjis_detail", { layout: VillaLayout, mapType: MapType.villa })
aptMapRouter.set("/home/villa_items_detail", { layout: VillaLayout, mapType: MapType.villa })
aptMapRouter.set("/home/villa_building_detail", { layout: VillaLayout, mapType: MapType.villa })
aptMapRouter.set("/home/villa_subways_items", { layout: VillaLayout, mapType: MapType.villa })
aptMapRouter.set("/home/villa_schools_detail", { layout: VillaLayout, mapType: MapType.villa }) // 아파트매물 | 신축분양 | 빌라

aptMapRouter.set("/home/store_map", { layout: StoreLayout, mapType: MapType.store })
aptMapRouter.set("/home/store_items", { layout: StoreLayout, mapType: MapType.store })
aptMapRouter.set("/home/store_items_detail", { layout: StoreLayout, mapType: MapType.store })
