import _ from "lodash"
import { NextRouter } from "next/router"
import { RefObject } from "react"
import { RouterAction } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { PageStatus, WrapHandle } from "@zigbang/screens/lib/navigation/ReactNavigationMapper/Wrap"

type BeforePopStateParams = Parameters<Parameters<NextRouter["beforePopState"]>[0]>[0] & {
	index: number
	hsTime: string
}

export class StackV2 {
	routeMode: RouterAction = RouterAction.undefined
	router: NextRouter
	createFn?: (key: number) => HistoryObject
	historyStack: HistoryObject[] = []
	currentPage?: HistoryObject
	nextPage?: HistoryObject
	private cacheCurrentTime?: string
	private pageKey = 0
	private originalPushState: any
	private originalReplaceState: any
	private get historyTime() {
		if (!process.browser) {
			return ""
		}
		const time = sessionStorage.getItem("HISTORY_TIME")
		if (time) {
			return time
		}
		const now = Date.now().toString()
		sessionStorage.setItem("HISTORY_TIME", now)
		return now
	}
	private get historyIndex() {
		if (!process.browser) {
			return 0
		}
		return Number(sessionStorage.getItem("HISTORY_INDEX")) || 0
	}
	private set historyIndex(index: number) {
		sessionStorage.setItem("HISTORY_INDEX", index.toString())
	}
	constructor(router: NextRouter) {
		this.router = router
	}
	onRouteChangeComplete = () => {
		this.cacheCurrentTime = history.state && history.state.hsTime
	}
	didMount() {
		this.originalPushState = history.pushState
		this.originalReplaceState = history.replaceState
		const { historyTime } = this
		this.cacheCurrentTime = historyTime
		this.router.beforePopState(({ url, as, options, index = 0, hsTime = historyTime }: BeforePopStateParams) => {
			const { historyIndex } = this
			let action = RouterAction.nothing
			if (hsTime !== historyTime || this.cacheCurrentTime !== hsTime) {
				action = RouterAction.unknown
			} else if (index < historyIndex) {
				action = RouterAction.pop
			} else if (index > historyIndex) {
				action = RouterAction.push
			}
			this.routeMode = action
			this.historyIndex = index
			return true
		})

		this.router.events.on("routeChangeComplete", this.onRouteChangeComplete)

		history.pushState = (state, title, url) => {
			const newState = { ...state, index: ++this.historyIndex, hsTime: historyTime }
			this.routeMode = RouterAction.push
			return this.originalPushState.call(history, newState, title, url)
		}

		history.replaceState = (state, title, url = location.href) =>
			this.originalReplaceState.call(history, { ...(history.state || {}), ...state }, title, url)
		history.replaceState({ ...history.state, index: this.historyIndex, hsTime: historyTime }, "")
		if (this.currentPage) {
			this.currentPage.historyState = history.state
		}
	}
	willUnmount() {
		this.router.events.off("routeChangeComplete", this.onRouteChangeComplete)
		history.pushState = this.originalPushState
		history.replaceState = this.originalReplaceState
	}
	render(
		createFn: (key: number) => HistoryObject,
		popCallback?: (historyObj: HistoryObject) => void,
		pushCallback?: (historyObj: HistoryObject) => void
	) {
		const historyIndex = process.browser && history.state ? history.state.index : 0
		this.createFn = createFn
		switch (this.routeMode) {
			case RouterAction.pop: {
				if (this.currentPage?.ref.current) {
					this.nextPage = this.currentPage
					this.nextPage.ref.current?.changePageStatus(PageStatus.willGone)
				}
				if (!this.historyStack.length) {
					this.createComponent(++this.pageKey)
				}
				// 쌓여있는 스택이 없는 경우
				else {
					this.currentPage = this.historyStack.pop()
					const {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars, max-len
						options: unusedOptionA,
						...currentPageHistoryState
					}: { options?: any; [key: string]: any } = this.currentPage?.historyState ?? {}
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { options: unusedOptionB, ...newPageHistoryState }: { options?: any; [key: string]: any } =
						history.state ?? {}

					if (this.currentPage && _.isEqual(currentPageHistoryState, newPageHistoryState)) {
						this.currentPage.ref.current?.changePageStatus(PageStatus.willFocusFormBack)
						popCallback?.(this.currentPage)
						// debugger
					} else {
						// 쌓여있는 스택이 있지만 이동한 페이지가 직전 페이지가 아닌경우 초기화
						this.historyStack = []
						this.createComponent(++this.pageKey)
					}
				}
				this.historyIndex = historyIndex
				break
			}
			case RouterAction.push: {
				if (this.currentPage) {
					this.currentPage.ref.current?.changePageStatus(PageStatus.willBlur)
					this.historyStack.push(this.currentPage)
					pushCallback?.(this.currentPage)
				}
				this.createComponent(++this.pageKey)
				this.historyIndex = historyIndex
				break
			}
			case RouterAction.undefined: {
				this.createComponent(undefined)
				break
			}
			case RouterAction.replace:
			case RouterAction.nothing:
			case RouterAction.unknown:
			default: {
				// @ts-ignore
				this.createComponent(this.currentPage?.component?.key)
			}
		}
		const Pages = this.historyStack.concat(this.currentPage!)
		if (this.nextPage) {
			Pages.push(this.nextPage)
		}
		this.routeMode = RouterAction.unknown
		return Pages.map(({ component }) => component)
	}
	createComponent(key = this.pageKey) {
		const { ref, component } = this.createFn!(key)
		this.currentPage = { ref, component, historyState: process.browser && history.state }
	}
}

export interface HistoryObject {
	ref: RefObject<WrapHandle>
	component: JSX.Element
	historyState?: { [key: string]: any }
}
