import { NextRouter } from "next/router"
import React from "react"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { StackNavigationOptions } from "@react-navigation/stack"
import { HomeOfficetelMapScreen } from "@zigbang/screens/home/officetel/HomeOfficetelMapScreen"
import { LayoutOptions, ReactNavigationMapper } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { SelectedMarker } from "@zigbang/screens/lib/WebMapStore"
import { OfficetelRouterName } from "@zigbang/screens/routes/officetel/types"
import { Rect } from "@zigbang/utils"

import { ContentLayer, DetailArea, Root } from "./AptMapOfferLayoutStyle"
import MapWrapper from "./MapWrapper"
import { PageContainerV2 } from "./PageContainerV2"

export class OfficetelLayout extends React.Component<Props> {
	static layoutOptions = {
		noFooter: true,
	}

	static cloneInstance = (inst: any, additionalProps?: { [key: string]: any }) =>
		Object.assign(Object.create(Object.getPrototypeOf(inst)), inst, additionalProps)

	state: State = {
		rect: undefined,
		selectMarkerData: undefined,
		infoMarker: undefined,
	}
	dragging = false
	mapScreen: any = React.createRef()

	render() {
		const { navigation, rightScreen: Component, router, navigationRoute, routeName, ...restProps } = this.props
		if (!router || !router.pathname) {
			return null
		}
		let { query } = this.props
		if (
			this.props.router.pathname === "/home/officetel_items" ||
			this.props.router.pathname === "/home/officetel_map" ||
			this.props.router.pathname === "/home/officetel_subways_items" ||
			this.props.router.pathname === "/home/officetel_danjis_items"
		) {
			query = { ...this.props.query, ...this.state.rect }
		}

		const pageContainer = (
			<PageContainerV2
				{...{ router, Component, routeName, ...restProps }}
				query={query}
				asPath={this.props.asPath}
				updateMarker={this.updateMarker}
				onHoverItemIn={this.onHoverItemIn}
				onHoverItemOut={this.onHoverItemOut}
			/>
		)
		return (
			<Root>
				<MapWrapper
					MapComponent={HomeOfficetelMapScreen}
					innerRef={this.mapScreen}
					navigation={navigation}
					route={navigationRoute}
					onPanDrag={this.onPanDrag}
					onRegionChangeComplete={this.onRegionChangeComplete}
					infoMarker={this.state.infoMarker}
					selectMarkerData={this.state.selectMarkerData}
				/>
				{/* <MapFilter searchResult={this.onClickSearchResult} mode={MapType.officetel} /> */}
				<DetailArea>
					<ContentLayer>{pageContainer}</ContentLayer>
				</DetailArea>
			</Root>
		)
	}

	onRegionChangeComplete = (rect: Rect) => {
		if (this.dragging) {
			if (
				this.props.router.pathname !== "/home/officetel_items" &&
				this.props.router.pathname !== "/home/officetel_map"
			) {
				this.props.navigation.push(OfficetelRouterName.HomeOfficetelMap)
			}
			this.dragging = false
		}
		this.setState({ rect })
	}
	onClickSearchResult = (item: { type: string; value: any }) => {
		//
	}

	onPanDrag = () => (this.dragging = true)

	onHoverItemIn = (marker: State["infoMarker"]) => {
		this.setState({ infoMarker: marker })
	}
	onHoverItemOut = () => {
		this.setState({ infoMarker: undefined })
	}

	updateMarker = ({ latitude, longitude, id, markerType, fixedZoom, exception, zoom }: SelectedMarker) => {
		const { isSSR, moveRegion } = this.props

		if ((exception || !isSSR) && !moveRegion) {
			return
		}
		this.setState({ selectMarkerData: { latitude, longitude, id, markerType, fixedZoom, zoom } })
	}
}

interface Props {
	navigation: ReactNavigationMapper
	rightScreen: React.ComponentType<any> & {
		navigationOptions: StackNavigationOptions
		layoutOptions: LayoutOptions
	}
	router: NextRouter
	area_danji_id?: string
	routeName: string
	asPath: string
	query: { [key: string]: unknown }
	isSSR?: boolean
	navigationRoute: RouteProp<ParamListBase>
	moveRegion?: boolean
}

interface State {
	rect?: Rect
	selectMarkerData?: SelectedMarker
	infoMarker?: { element: JSX.Element; coordinate: { latitude: number; longitude: number }; key: number | string }
}
